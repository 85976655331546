/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router";
import notFound from "@/views/sections/notFound.vue";
import Main from "@/views/MainView.vue";

const routes = [
    {
        path: "/",
        name: "Main",
        component: Main,
        meta: {
            label: "home",
        },
        children: [
            {
                path: "",
                name: "Home",
                component: () => import("@/views/home/HomeView.vue"),
                meta: {
                    label: "home",
                },
            },
            {
                path: "check-service-doc/:id",
                name: "CheckServiceDoc",
                meta: {
                    label: "CheckServiceDoc",
                },
                children: [
                    {
                        path: "",
                        name: "CheckServiceDocMain",
                        component: () =>
                            import("@/views/check-service-doc/index.vue"),
                    },
                    // {
                    //   path: ":id",
                    //   name: "NewsInner",
                    //   component: () => import("@/views/news/pages/NewsInner.vue"),
                    // },
                ],
            },
            {
                path: "duty-table",
                name: "dutySchedule",
                meta: {
                    label: "dutySchedule",
                },
                children: [
                    {
                        path: "",
                        name: "dutySchedule",
                        component: () => import("@/views/duty-table/index.vue"),
                    },
                    {
                        path: "view/:id",
                        name: "dutyScheduleView",
                        component: () => import("@/views/duty-table/view.vue"),
                    },
                ],
            },
            {
                path: "profile",
                name: "Profile",
                component: () => import("@/views/profile/ProfileView.vue"),
                meta: {
                    auth: true,
                    label: "profile",
                },
                children: [
                    // application
                    {
                        path: "aplication",
                        name: "Guvohnoma-list",
                        component: () =>
                            import("@/views/profile/pages/GuvohnomaList.vue"),
                    },
                    {
                        path: "order",
                        name: "Buyruq-list",
                        meta: {
                            label: "order",
                        },
                        component: () =>
                            import("@/views/profile/pages/order/Index.vue"),
                    },
                    {
                        path: "yumom",
                        name: "Yumom-list",
                        meta: {
                            label: "trainCertificate",
                        },
                        component: () =>
                            import("@/views/profile/pages/YumomList.vue"),
                    },
                    {
                        path: "contracts",
                        name: "Contracts",
                        meta: {
                            label: "contracts",
                        },
                        component: () =>
                            import("@/views/profile/pages/contracts/Index.vue"),
                    },
                    {
                        path: "notifications",
                        name: "Notifications",
                        meta: {
                            label: "notifications",
                        },
                        component: () =>
                            import(
                                "@/views/profile/pages/notification/Index.vue"
                            ),
                    },
                    {
                        path: "forum",
                        name: "Forum",
                        meta: {
                            label: "forum",
                        },
                        component: () =>
                            import("@/views/profile/pages/forum/Index.vue"),
                        children: [
                            {
                                path: "",
                                name: "ForumListView",
                                component: () =>
                                    import(
                                        "@/views/profile/pages/forum/pages/List.vue"
                                    ),
                            },
                        ],
                    },
                ],
            },
            {
                path: "news",
                name: "News",
                component: () => import("@/views/news/NewsView.vue"),
                meta: {
                    label: "news",
                },
                children: [
                    {
                        path: "",
                        name: "NewsMain",
                        component: () =>
                            import("@/views/news/pages/NewsBodyView.vue"),
                    },
                    {
                        path: ":id",
                        name: "NewsInner",
                        component: () =>
                            import("@/views/news/pages/NewsInner.vue"),
                    },
                ],
            },
            {
                path: "announcement",
                name: "AnnouncementView",
                component: () =>
                    import("@/views/announcement/pages/AnnouncementView"),
                meta: {
                    label: "announcement",
                },
                children: [
                    {
                        path: "",
                        name: "AnnouncementMain",
                        component: () =>
                            import(
                                "@/views/announcement/pages/AnnouncementPage"
                            ),
                    },
                    // {
                    //     path: ":id",
                    //     name: "NewsInner",
                    //     component: () =>
                    //         import("@/views/news/pages/NewsInner.vue"),
                    // },
                ],
            },
            {
                path: "lawyer",
                name: "Lawyer",
                component: () => import("@/views/lawyer/LawyerView.vue"),
                meta: {
                    label: "lawyers",
                },
                children: [
                    {
                        path: "",
                        name: "LawyerMain",
                        component: () =>
                            import("@/views/lawyer/pages/LawyerBodyView.vue"),
                    },
                    // {
                    //   path: ":id",
                    //   name: "NewsInner",
                    //   component: () => import("@/views/news/pages/NewsInner.vue"),
                    // },
                ],
            },
            {
                path: "contragent",
                name: "Contragent",
                component: () =>
                    import("@/views/contragent/ContragentView.vue"),
                meta: {
                    label: "contragent",
                },
                children: [
                    {
                        path: "",
                        name: "ContragentMain",
                        component: () =>
                            import(
                                "@/views/contragent/pages/ContragentBodyView.vue"
                            ),
                    },
                    // {
                    //   path: ":id",
                    //   name: "NewsInner",
                    //   component: () => import("@/views/news/pages/NewsInner.vue"),
                    // },
                ],
            },
            {
                path: "legislation",
                name: "Legislation",
                component: () =>
                    import("@/views/legislation/LegislationView.vue"),
                meta: {
                    label: "legislation",
                },
                children: [
                    {
                        path: "",
                        name: "LegislationMain",
                        component: () =>
                            import(
                                "@/views/legislation/pages/LegislationBodyView.vue"
                            ),
                    },
                    // {
                    //   path: ":id",
                    //   name: "NewsInner",
                    //   component: () => import("@/views/news/pages/NewsInner.vue"),
                    // },
                ],
            },

            {
                path: "/:pathMatch(.*)*",
                redirect: { name: "NotFound" },
            },
        ],
    },
    {
        path: "/lawyer-profile",
        name: "LawyerProfile",
        component: () => import("@/views/lawyer-profile/Index.vue"),
        meta: {
            auth: true,
            label: "profile",
        },
        children: [
            {
                path: "",
                // redirect: { name: "LawyerProfileMain" },
                redirect: { name: "LawyerProfileInfo" },
            },

            {
                path: "profile-info",
                name: "LawyerProfileInfo",
                component: () =>
                    import("@/views/lawyer-profile/pages/profile/Index.vue"),
            },
            {
                path: "applicant",
                name: "ProfileView",
                component: () =>
                    import("@/views/profile/pages/profile/ProfileBodyView.vue"),
            },
            {
                path: "user-info",
                meta: {
                    label: "user.user",
                    auth: true,
                },
                name: "UserInfo",
                component: () =>
                    import("@/views/lawyer-profile/pages/users/Index.vue"),
            },
            {
                path: "my-appeals",
                meta: {
                    label: "forPersonsWithDisabilities",
                    auth: true,
                },
                children: [
                    {
                        path: "",
                        name: "MyAppeals",
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/users/Index.vue"
                            ),
                    },
                    {
                        path: "appeals",
                        name: "allAppeals",
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/user-requests/List.vue"
                            ),
                    },
                    {
                        path: "create/:mode",
                        name: "FreeLawyer",
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/user-requests/FreeLawyerReq.vue"
                            ),
                    },
                    {
                        path: "view/:id",
                        name: "ViewAppeal",
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/user-requests/View.vue"
                            ),
                    },
                ],
            },
            {
                path: "my-applications",
                meta: {
                    label: "citizensInTheSocialSecurityRegister",
                    auth: true,
                },
                children: [
                    {
                        path: "",
                        name: "applications",
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/applications/index.vue"
                            ),
                    },
                    {
                        meta: {
                            label: "applications-create",
                        },
                        path: "create",
                        name: "applications-create",
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/applications/form/index.vue"
                            ),
                    },
                ],
            },

            {
                path: "announcement",
                meta: {
                    label: "132123",
                    auth: true,
                },
                children: [
                    {
                        path: "",
                        name: "announcement",
                        component: () =>
                            import("@/views/announcement/List.vue"),
                    },
                    {
                        meta: {
                            label: "applications-create",
                        },
                        path: "create",
                        name: "applications-create",
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/applications/form/index.vue"
                            ),
                    },
                ],
            },
            {
                path: "notifications",
                name: "Notifications",
                meta: {
                    label: "notifications",
                },
                component: () =>
                    import("@/views/profile/pages/notification/Index.vue"),
            },
            {
                path: "yumom-certificate",
                name: "Yumom-list",
                meta: {
                    label: "trainCertificate",
                },
                component: () => import("@/views/profile/pages/YumomList.vue"),
            },
            {
                path: "control",
                redirect: { name: "Boshqaruv" },
                children: [
                    {
                        path: "",
                        name: "Boshqaruv",
                        meta: {
                            label: "control",
                        },
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/employees/Index.vue"
                            ),
                    },
                    {
                        meta: {
                            label: "structure-information-update",
                        },
                        path: "structure-information/update",
                        name: "structure-information",
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/employees/StructureInformationUpdate.vue"
                            ),
                    },
                ],
            },

            {
                path: "control-panel",
                meta: {
                    label: "sidebar.controlPanel",
                },
                children: [
                    {
                        path: "",
                        name: "LawyerProfileMain",
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/control/Index.vue"
                            ),
                    },
                ],
            },
            {
                path: "contracts",
                name: "LawyerProfileContracts",
                component: () =>
                    import("@/views/profile/pages/contracts/Index.vue"),
                meta: {
                    label: "profile",
                },
            },
            {
                path: "letters-and-documents/:type",
                name: "correspondence",
                component: () =>
                    import("@/views/letters-and-documents/index.vue"),
                meta: {
                    label: "correspondence",
                },
            },
            {
                path: "letters-and-documents-contragent-or-lawyer",
                name: "correspondenceContragenOrLawyer",
                component: () =>
                    import(
                        "@/views/letters-and-documents/contragentOrLawyerList.vue"
                    ),
                meta: {
                    label: "correspondence",
                },
            },
            {
                path: "letters-and-documents-incoming",
                name: "correspondenceIncoming",
                component: () =>
                    import("@/views/letters-and-documents/incoming.vue"),
                meta: {
                    label: "correspondence",
                },
            },
            {
                path: "letters/doc-template",
                redirect: { name: "DocTemplate" },
                children: [
                    {
                        path: "",
                        name: "DocTemplate",
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/doc-template/index.vue"
                            ),
                        meta: {
                            label: "docTemplate.list",
                        },
                    },
                ],
            },
            {
                path: "letters",
                redirect: { name: "LawyerProfileLetters" },
                children: [
                    {
                        path: "",
                        name: "LawyerProfileLetters",
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/letters/index.vue"
                            ),
                        meta: {
                            label: "request.list",
                        },
                    },
                ],
            },
            {
                path: "requests",
                redirect: { name: "LawyerProfileRequests" },
                children: [
                    {
                        path: "",
                        name: "LawyerProfileRequests",
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/requests/List.vue"
                            ),
                        meta: {
                            label: "request.list",
                        },
                    },
                    {
                        path: "add-legal/:id",
                        name: "LawyerProfileRequestsAddLegal",
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/requests/LegalAidAdd.vue"
                            ),
                    },
                    {
                        path: "add-certificate/:id",
                        name: "LawyerProfileRequestsAddCertificate",
                        params: {
                            reSendId: null,
                        },
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/requests/CertificateAdd.vue"
                            ),
                    },
                    {
                        path: "add-certificate/view/:id",
                        name: "LawyerProfileRequestsViewCertificate",
                        params: {
                            reSendId: null,
                        },
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/requests/CertificateView.vue"
                            ),
                    },
                    {
                        path: "add-certificate/reSend/:reSend",
                        name: "LawyerProfileRequestsResentCertificate",
                        params: {
                            reSendId: null,
                        },
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/requests/CertificateAdd.vue"
                            ),
                    },
                    {
                        path: "add-license/:id",
                        name: "LawyerProfileRequestsAddLicense",
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/requests/LicenseAdd.vue"
                            ),
                    },
                    {
                        path: "add-status/:id",
                        name: "LawyerProfileRequestsAddStatus",
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/requests/Status.vue"
                            ),
                    },
                ],
            },
            {
                path: "orders",
                name: "LawyerProfileOrders",
                redirect: { name: "LawyerProfileOrdersList" },
                meta: {
                    label: "orders.all",
                },
                children: [
                    {
                        path: "",
                        redirect: { name: "LawyerProfileOrdersList" },
                    },
                    {
                        path: "list",
                        name: "LawyerProfileOrdersList",
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/order/List.vue"
                            ),
                        meta: {
                            label: "orders.personalOrders",
                        },
                    },
                    {
                        path: "list/view/:id",
                        name: "LawyerProfileOrdersListView",
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/order/View.vue"
                            ),
                        meta: {
                            label: "orders.personalOrdersView",
                        },
                    },
                    {
                        path: "create/:type",
                        name: "LawyerProfileOrdersCreate",
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/order/Add.vue"
                            ),
                    },
                    {
                        path: "contragents",
                        name: "LawyerProfileOrdersContragents",
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/order/Contragents.vue"
                            ),
                        meta: {
                            label: "orders.contragentOrders",
                        },
                    },
                    {
                        path: "expertise/:orderId",
                        name: "LawyerProfileOrderExpertise",
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/order/expertise/List"
                                ),
                        meta: {
                            label: "orders.expertise",
                        },
                    },
                    {
                        path: "expertise/view/:id",
                        name: "LawyerProfileOrderExpertiseView",
                        component: () =>
                            import(
                                "@/views/lawyer-profile/pages/order/expertise/viewExpertise"
                                ),
                        meta: {
                            label: "orders.expertise",
                        },
                    },

                ],
            },
            {
                path: "application",
                name: "LawyerApplicationList",
                redirect: { name: "application" },
                meta: {
                    label: "lawyerApplication.all",
                },
                children: [
                    {
                        path: "",
                        redirect: { name: "LawyerApplicationList" },
                    },
                    {
                        path: "list",
                        name: "List",
                        component: () =>
                            import(
                                "@/views/lawyer-application/my-intern/MyInternList"
                            ),
                        meta: {
                            label: "orders.personalOrders",
                        },
                    },

                    {
                        path: "intern",
                        name: "InternList",
                        component: () =>
                            import(
                                "@/views/lawyer-application/intern/InternList"
                            ),
                        meta: {
                            label: "orders.personalOrders",
                        },
                    },
                    {
                        path: "intern-restr",
                        name: "InternRestrList",
                        component: () =>
                            import(
                                "@/views/lawyer-application/intern/intern-restr/InternRestrList"
                            ),
                        meta: {
                            label: "orders.personalOrders",
                        },
                    },
                    {
                        path: "intern-restr/create",
                        name: "InternRestrCreate",
                        component: () =>
                            import(
                                "@/views/lawyer-application/intern/intern-restr/CreateInternRestr"
                            ),
                        meta: {
                            label: "orders.personalOrders",
                        },
                    },
                    {
                        path: "intern/:id",
                        name: "InternView",
                        component: () =>
                            import(
                                "@/views/lawyer-application/intern/InternView"
                            ),
                        meta: {
                            label: "orders.personalOrders",
                        },
                    },
                    {
                        path: "intern-restr/:id",
                        name: "InternRestrViewView",
                        component: () =>
                            import(
                                "@/views/lawyer-application/intern/intern-restr/InternRestrView"
                            ),
                        meta: {
                            label: "orders.personalOrders",
                        },
                    },
                    {
                        path: "my-intern/:id",
                        name: "MyInternView",
                        component: () =>
                            import(
                                "@/views/lawyer-application/my-intern/MyInternView"
                            ),
                        meta: {
                            label: "orders.personalOrders",
                        },
                    },
                    {
                        path: "form",
                        name: "LawyerApplicationForm",
                        component: () =>
                            import(
                                "@/views/lawyer-application/LawyerApplicationFormPage"
                            ),
                    },
                    {
                        path: "announcement/:id/:contragentId/:applicationTypeId",
                        name: "AnnouncementForm",
                        component: () =>
                            import(
                                "@/views/lawyer-application/intern/AnnouncementForm"
                            ),
                    },
                ],
            },
        ],
    },
    {
        path: "/one-id",
        name: "oneId",
        component: () => import("@/views/oneId/oneIdView.vue"),
        meta: {
            public: true, // Allow access to even if not logged in
            onlyWhenLoggedOut: true,
        },
    },
    {
        path: "/one-id/announcement",
        name: "oneIdAnnouncementForm",
        component: () => import("@/views/oneId/oneIdVacancyForm.vue"),
        meta: {
            public: true, // Allow access to even if not logged in
            onlyWhenLoggedOut: true,
        },
    },
    {
        path: "/announcement/:id/:contragentId/:applicationTypeId",
        name: "oneIdAnnouncementView",
        component: () => import("@/views/oneId/oneIdAnnouncementView.vue"),
        meta: {
            public: true, // Allow access to even if not logged in
            onlyWhenLoggedOut: true,
        },
    },
    {
        path: "/contracts/:id",
        name: "LawyerContracts",
        component: () =>
            import("@/views/lawyer-profile/pages/requests/contracts.vue"),
    },
    {
        path: "/expertise/payment/:transactionId",
        name: "LawyerProfileOrderExpertisePaymentDetail",
        component: () =>
            import(
                "@/views/lawyer-profile/pages/order/expertise/paymentDetail"
                ),
        meta: {
            label: "orders.paymentDetail",
        },
    },
    {
        path: "/mobile",
        name: "Mobile",
        component: () => import("@/views/mobile/Index.vue"),
        children: [
            {
                path: "",
                name: "MobileMain",
                // component: () => import("@/views/mobile/main/Index.vue"),
                redirect: { name: "MobileNews" },
            },
            {
                path: "appeals",
                name: "MobileAppeals",
                component: () => import("@/views/mobile/appeals/Index.vue"),
                children: [
                    {
                        path: "",
                        name: "MobileAppealsMain",
                        component: () =>
                            import("@/views/mobile/appeals/Main.vue"),
                    },
                    {
                        path: "list",
                        name: "MobileAppealsList",
                        component: () =>
                            import("@/views/mobile/appeals/List.vue"),
                    },
                    {
                        path: "view/:id",
                        name: "MobileAppealsView",
                        component: () =>
                            import("@/views/mobile/appeals/View.vue"),
                    },
                ],
            },
            {
                path: "news",
                name: "MobileNews",
                component: () => import("@/views/mobile/news/Index.vue"),
                meta: {
                    label: "news",
                },
                children: [
                    {
                        path: "",
                        name: "MobileNewsBody",
                        component: () =>
                            import(
                                "@/views/mobile/news/pages/NewsBodyView.vue"
                            ),
                    },
                    {
                        path: ":id",
                        name: "MobileNewsInner",
                        component: () =>
                            import("@/views/mobile/news/pages/NewsInner.vue"),
                    },
                ],
            },
            {
                path: "orders",
                name: "MobileOrders",
                redirect: { name: "MobileLawyersOrders" },
                meta: {
                    label: "orders.all",
                },
                children: [
                    {
                        path: "",
                        redirect: { name: "MobileLawyersOrders" },
                    },
                    {
                        path: "list",
                        name: "MobileLawyersOrders",
                        component: () =>
                            import("@/views/mobile/order/List.vue"),
                        meta: {
                            label: "orders.personalOrders",
                        },
                    },
                    {
                        path: "create/:type",
                        name: "MobileLawyersOrdersCreate",
                        component: () => import("@/views/mobile/order/Add.vue"),
                    },
                    {
                        path: "contragents",
                        name: "MobileLawyersOrdersContragents",
                        component: () =>
                            import("@/views/mobile/order/Contragents.vue"),
                        meta: {
                            label: "orders.contragentOrders",
                        },
                    },
                ],
            },
        ],
    },
    {
        path: "/404",
        name: "NotFound",
        component: notFound,
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: { name: "NotFound" },
    },
];

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {
                top: 0,
            };
        }
    },
    routes,
});

import { TokenService } from "@/services/storage.service";
import ApiService from "@/services/api.service";

router.beforeEach((to, from, next) => {
    // console.log("to", to);
    // console.log("from", from);
    const token = !!TokenService.getToken();
    if (to.meta?.auth) {
        token ? next() : ApiService.openOneId();
    } else next();
});

export default router;

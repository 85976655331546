export const locale = {
    // actions
    name: {
        lt: "Nomi (lotin)",
        ru: "Название (русский)",
        uz: "Номи (кирилл)",
        en: "Name (english)",
    },
    lang: {
        russian: "Русский",
        uzbekLatin: "O‘zbek (Lotin)",
        uzbekKrill: "Ўзбек (кирилл)",
        english: "English",
    },
    /////////\\\\\\STATUS\\\\\\\\\\\\///////
    status: {
        DRAFT: "Черновик",
        PRINTED: "Печатный",
        status: "Статус",
        active: "Активный",
        inactive: "Неактивный",
        notFound: "Неопределенный",
        SEND_LAWYER: "Yuborilgan",
        SEND: "Отправил",
        SEEN: "Рассмотрено",
        CANCELLED: "Oтменен",
        ACCEPTED: "Принято",
        MEETING: "беседа настроен",
        MEETING_PROCESS: "во время беседа",
        PASSED: "Прошел собеседование",
        FAILED: "Провалил собеседование",
        ABSENT: "не пришел на собеседование",
        cancelled: "Отменен",
        send: "Отправил",
        sendToSign: "Отправлено на подпись",
        signed: "Подписано",
        accepted: "Принято",
        signedDeportament: "Подписано департаментом",
        successInput: "Успешно внесен",
        COMPLETED: "Завершен",
        CLOSED: "Закрыт",
        SEND_CONVICTION: "Sudlananlikka tekshirilmoqda",
        CONFIRM: "Shartnoma tuzilgan",
    },

    winning: {
        card1: {
            title: "Отправка обращений",
            text: "Лля лиц с инвалидностью",
        },
        card2: {
            title: "Статистика адвокатов",
            text: "Вся статистика адвокатов и полезная информация",
        },
        card3: {
            title: "Рейтинг адвокатов",
            text: "Специальный рейтинг адвокатов и работа с ними",
        },
    },
    views: "Просмотр",
    categorieslist: {
        item1: {
            title: "Преступление - административное",
            text: "О внесении изменений и дополнений в Уголовный, Уголовно-процессуальный и Административно-правовой кодексы Республики Узбекистан",
        },
        item2: {
            title: "Гражданство - экономическое",
            text: "О внесении изменений и дополнений в Уголовный, Уголовно-процессуальный и Административно-правовой кодексы Республики Узбекистан",
        },
    },
    contract: {
        signing: "Подписание договора",
        numberDate: "Номер и дата договора",
    },
    editProfile: {
        title: "Редактирование профиля",
        name: "Имя",
        surname: "Фамилия",
        patronymic: "Отчество",
        phone: "Телефон",
        email: "Электронная почта",
        picture: "Фото",
        address: "Адрес",
    },
    dialog: {
        yes: "Да",
        no: "Нет",
    },
    orders: {
        all: "Ордеры",
        draft: "Черновик",
        orderInfo: "Информация об ордере",
        electronOrder: "В электронном виде ордеры",
        documenttalOrder: "В бумажном виде ордеры",
        groundsForGiven: "Основания для выдачи",
        viewPeperForm: "Просмотреть бумажную форму",
        sureCancelOrder: "Вы уверены, что хотите отменить ордер?",
        createEOrder: "Создать электронный ордер",
        list: "Список ордеров",
        countsAll: "Всего ордеров",
        sends: "Отправлено",
        cancelleds: "Отменено",
        snetLate: "Отправлено поздно",
        electron: "Электронный",
        paper: "Бумажный",
        youNorCreateYet: "Вы еще не создали ни одного ордера",
        viewPpaperForm: "Просмотреть бумажную форму",
        viewOrder: "Просмотреть ордер",
        addToReportForm: "Добавить в отчетную форму",
        sendOrder: "Отправить ордер",
        createElectronOrder: "Создать электронный ордер",
        createPaperOrder: "Создать бумажный ордер",
        whatOrderWho: "ПРИКАЗ на ведение дела адвокатом",
        selectedDocumentNumber: "Номер выбранного документа",
        selectedDocumentDate:
            "Дата выдачи документа, являющегося основанием для издания приказа",
        personSelectTitle: "Сведения о доверительном (защищаемом) лице",
        orderDate: "Дата ордера",
        inputFileOrder: "Загрузите файл бумажного ордера",
        inputPaperFormOrder: "Прикрепить бумажную форму ордера",
        legalAidWorkType:
            "Вид дела, по которому оказывается юридическая помощь",
        orderType: "Тип ордера",
        saveToDrafts: "Сохранить в черновиках",
        sureDelete: "Вы уверены, что хотите удалить ордер?",
        personalOrders: "Персональные ордеры",
        contragentOrders: "Ордеры контрагента",
        createdDate: "Дата создания ордера",
        createFeature: "Создать ордер по особенности",
        canCreatePermission: "Разрешить создание ордеров",
        limitedByContragent: "Создание ордера ограничено структурой",
        expertise: "Экспертиза"
    },
    sidebar: {
        controlPanel: "Контрольная панель",
        incoming: "Входящие",
        myWorks: "Мои работы",
        outgoing: "Исходящие",
        draft: "Черновики",
        docTemplate: "Шаблонлар",
        requests: "Запросы",
        contracts: "Договоры",
        orders: "Ордеры",
        statisticInfo: "Статистическая информация",
        contragentOrLawyerLetters: "Переписка между адвокатами",
        profile: "Профиль",
        letterDocuments: "Письма и документы",
        control: "Контроль",
    },
    aLetterCameFrom: "Вам пришло письмо от {fullName}",
    fromWho: "От кого",
    responseLetter: "Ответное письмо",
    incomingLetter: "Входящее письмо",
    toWhichLetter: "К какому письму пришло",
    letterType: "Тип письма",
    sendResponseToLetter: "Отправить ответное письмо",
    sendLetter: "Отправить письмо",
    CheckServiceDoc: "Проверить документ",
    success: {
        editeProfile: "Профиль успешно изменен",
        delete: "Успешно удалено",
        save: "Успешно сохранено",
        cancel: "Успешно отменено",
        completed: "Успешно завершено",
    },
    lawyer: {
        FISH: "Адвокат ФИО",
    },
    times: {
        month: "Месяц",
        day: "День",
        week: "Неделя",
    },
    months: {
        january: "Январь",
        february: "Февраль",
        march: "Март",
        april: "Апрель",
        may: "Май",
        june: "Июнь",
        july: "Июль",
        august: "Август",
        september: "Сентябрь",
        october: "Октябрь",
        november: "Ноябрь",
        december: "Декабрь",
    },
    request: {
        list: "Список запросов",
        create: "Создать запрос",
        allCount: "Всего запросов",
        inProgress: "В работе",
        accepteds: "Принятые",
        cancels: "Отмененные",
        all: "Все запросы",
        progresses: "В работе",
        createDate: "Дата создания",
        number: "Номер запроса",
        type: "Тип запроса",
        status: "Статус запроса",
        view: "Просмотреть запрос",
        requestForSendLegalAid: "Запрос на оказание юридической помощи",
        cancelCreatePermission:
            "Вы уверены, что хотите отменить создание запроса?",
        replaceCertificate: "Запрос на замену сертификата",
        replaceLicense: "Запрос на замену лицензии",
        statusStoping: "Заявление о приостановлении статуса адвоката",
        imgForLawyerCertificate: "Загрузите фото сертификата адвоката",
        forFreeHelp: "Запрос на бесплатную юридическую помощь",
        imageReason:
            "Если вы не загрузите свою цветную фотографию 3х4, на сертификат будет помещена ваша фотография из базы данных Государственного центра персонализации.",
    },
    titles: {
        personalInfo: "Персональная информация",
        lawyerLicenseInfos: "Информация о лицензии адвоката",
        lawyerCertificateInfos: "Информация о сертификате адвоката",
        lawyerContragentInfos: "Информация о контрагенте адвоката",
        litcenseType: "Тип лицензии",
        licensingAuthority: "Орган, выдавший лицензию",
        certificatingAuthority: "Орган, выдавший сертификат",
        workExperienceAndAchievementsOfTheLawyer:
            "Опыт работы и достижения юриста",
        mainLinesOfActivity: "Основные направления деятельности",
    },
    lawyerStatuses: {
        licenceStop: "Лицензия приостановлена",
        licenceActive: "Лицензия активна",
        licenceEnd: "Лицензия закончена",
        statusStop: "Статус адвоката приостановлен",
        status: "Статус адвоката",
        licenseStatus: "Статус лицензии",
    },
    user: {
        user: "Пользователь",
        fullName: "ФИО",
        jshshr: "Персональный идентификационный номер (ПИНФЛ)",
        address: "Адрес",
        personalInfo: "Персональная информация",
        appeals: "Обращения",
        firstName: "Имя",
        lastName: "Фамилия",
        middleName: "Отчество",
    },
    appeals: {
        appeal: "Обращение",
        appeals: "Обращения",
        myAppeals: "Мои обращения",
        allAppeals: "Все обращения",
        acceptedAppeals: "Принятые обращения",
        create: "Создать обращение",
        region: "Регион",
        type: "Тип обращения",
        commentWork: "Краткое содержание работы",
        commentWork2: "Точное содержание работы",
        dontPermission: "У вас нету доступа для создания обращения.",
        youNotInvalide:
            "Данный раздел создан для содействия адвокатским формированиям и адвокатам в оказании лицам с инвалидностью добровольной бесплатной юридической помощи (pro bono). По Вашему ПИНФЛ данные о Вашей причастности к лицам с инвалидностью не найдены.",
        byAppealsInfos: "По информации обращения",
        empty: "Обращения отсутствуют",
        regionAndPhone: "Область и телефон",
        appealSender: "Отправитель обращения",
        accept: "Принять обращение",
        sureAccept:
            "Вы действительно хотите оказать бесплатную юридическую помощь по данному обращению?",
        accepted: "Принято",
        createEAppeals: "Создать э-обращение",
        contentWork: "Содержание работы",
        send: "Отправить обращение",
        fieldOf: "Поле обязательно для заполнения",
        dateSend: "Дата отправки",
        addFile: "Добавить файл",
        sureConfirm: "Я ознакомился с правилами отправки заявки",
        view: "Просмотреть обращение",
        viewUploadFile: "Просмотреть загруженный файл",
        complation: "Завершение обращения",
        attechedLawyer: "Прикрепленный адвокат",
        aboutCompleted: "О завершении обращения",
        certificateSendAddress: "Адрес для отправки адвокатского удостоверения",
        labelList:
            "Обращения инвалидов за добровольной бесплатной юридической помощью (pro bono).",
    },
    readMored: "Подробнее...",
    certificate: {
        changeNeason: "Причина получения/изменения сертификата",
    },
    licenseFileShow: "Просмотреть файл лицензии",
    requestAcceptedConnecting:
        "Запрос принят, свяжитесь с отправителем запроса",
    uploadFile: "Загрузить файл",
    id: "ИИН",
    cityTaoun: "Город/Район",
    applicant: "Заявитель",
    userRequests: "Запросы пользователя",
    lawyersConrtagent: "АдвокатЫ контрагента",
    legislativeNews: "Новости законодательства",
    yes: "Да",
    attention: "Внимание!",
    protectedPersonsAttention1:
        "1. Удалить фигурантов дела после их добавления невозможно!",
    protectedPersonsAttention2:
        "2. Если вы хотите получать информацию по уголовным или судебным делам в отношении добавленных лиц, или информацию в рамках Электронного правительства, вам необходимо ввести ПИНФЛ этих лиц",
    documentType: "Тип документа",
    drafts: "Черновики",
    electronSigning: "Электронная подпись",
    fieldMandatory: "Поле обязательно для заполнения",
    listEmpty: "Список пуст",
    protectedPerson: "Защищенное лицо",
    requireTimeRange: "Время ожидания",
    profile: "Профиль",
    systemRunningTestMode: "Система работает в тестовом режиме",
    shortAboutWork: "Кратко о работе",
    isProvided: "Юридическая помощь оказывается",
    awaitingYourReply: "Ожидает вашего ответа",
    cancelingRequest: "Отмена заявки",
    acceptingRequest: "Принятие заявки",
    specialty: "Специальность",
    seriaAndNumber: "Серия и номер",
    viewRequest: "Просмотр заявки",
    sendRepot: "Отправить отчет",
    successReport: "Отчет успешно отправлен",
    reportFile: "Файл отчета",
    acceptedRequest: "Принята заявка",
    perform: {
        null: "В пути",
        SuccessDelivered: "Доставлен",
        ReceiverDead: "Адресат умер",
        ReceiverNotLivesThere: "Адресат по указанному адресу не проживает",
        IncompleteAddress: "Указан не полный адрес",
        ReceiverRefuse: "Адресат от получения отказался",
        NotAtHome: "Нет дома",
        DidntAppearOnNotice: "Не явился по извещению",
        Defect: "Адрес не определен",
        TryPerform: "Попытка вручения",
        OrganizationWithGivenAddressNotFound:
            "По указанному адресу организация не найдена",
    },
    canceledRequest: "Отменена заявка",
    closedRequest: "Закрыта заявка",
    sentRepot: "Отправлен отчет",
    reportNotClose: "Отчет не закрыт",
    paymnetApproved: "Оплата одобрена",
    sendFiltesNotFull: "Отправить файлы не полностью",
    notifications: "Уведомления",
    certificateRequest: "Подать заявку на сертификат",
    order: "Ордер",
    dutyScheduleHeader:
        "ГРАФИК ДЕЖУРСТВА адвокатов, оказывающих юридическую помощь по уголовным делам за государственный счет в городе Ташкент в {month} {year} года",
    selectfile: "Выбрать из файлов",
    drophere: "Или скинь сюда",
    sendorders: "Отправленные ордеры",
    createOrder: "Создать новый ордеры y",
    hour: "час",
    onlinerequest: "ЗАПИСАТЬСЯ НА ПРИЕМ ОНЛАЙН",
    share: "Делиться",
    contragentWorkers: "Сотрудники организации",
    sureDeleteLawyer: "Вы уверены, что хотите уволить этого сотрудника?",
    successMuve: "Сотрудник был успешно освобожден",
    toWhom: "кому это дано",
    contractDate: "Дата контракта",
    sureSubmitLawyer: "Вы действительно наняли бы этого работника?",
    successSubmit: "Сотрудник был успешно принят на работу",
    forum: "Форум",

    Name: "Название",
    type: "Тип",
    fonLawyers: "Решения, касающиеся адвокатов",
    numberDoc: "Document number",
    mainName: "Министерство юстиции ",
    mainNameStrong: "Республики Узбекистан",
    contragentFullName: "Полное наименование адвокатской структуры",
    contragentName: "Название адвокатской структуры",
    projectName: "Автоматизированная система подбора юристов",
    lawyerCertificate: "Сертификат адвоката",
    username: "Имя пользователя",
    firstName: "Имя",
    lastName: "Фамилия",
    parentName: "отчество",
    requesite: "Реквизит",
    date: "Дата",
    wokEnd: "Срок годности",
    startWork: "Дата приема на работу",
    endWork: "Дата увольнения",
    workPlace: "Место работы",
    phone: "Номер телефона",
    email: "Электронный почтовый адрес",
    password: "Пароль",
    signUp: "Регистрация",
    lawyerrester: "Реестр адвокатов",
    position: "Должность",
    andOther: "Другой",
    countWorkers: "Количество работников",
    WARNING: "Срок действия сертификата истекает",
    DANGER: "Срок действия сертификата истекает",
    EXPIRED: "Срок действия сертификата истек",
    allRestore: "Загрузить все данные",
    courseTotal: "Дата начала / дата окончания",
    coursSize: "Продолжительность курса",
    signed: "Подписано",
    nextTrain: "Следующий обучения",
    didntHaveCertificate: "Тем, кто не получил сертификат",
    view: "Вид",
    evoluation: "Оценка",
    providingLegal: "Юридическая помощь",
    submitAplication: "Подать заявку",
    businessLanguage: "Деловой язык",
    areaOfOperation: "Область деятельности",
    formingAnAplication: "Формирование заявки",
    contractPeriod: "Срок действия контракта",
    month: "Месяц",
    administartion: "Администрация",
    requests: "Заявки",
    requestType: "Тип заявки",
    contracts: "Контракты",
    signContract: "Подписать контракт",
    cancelingContract: "Отмена контракта",
    viewContract: "Просмотр контракта",
    laawyerAttorneyNumber: "номер счета адвоката",
    MFO: "МФО",
    successSign: "Успешно подписано",
    contractWasSigned: "Контракт был успешно подписан",
    sentForSignature: "Отправлено на подпись",
    sentToDdeportment: "Отправлено в отдел",
    rquestAccepted: "Заявка принята",
    //  actions
    errorReq: "Это поле не может быть пустым!",
    errorEmail: "Адрес электронной почты введен неверно",
    errorField: "Данные введены неправильно",
    search: "Поиск",
    deleteIt: "Вы действительно хотите удалить?",
    successSave: "Успешно сохранено",
    actions: "Действия",
    notFound: "Информация не найдена",
    save: "Сохранять",
    code: "Коди",
    home: "Главная страница",
    add: "Добавить",
    addEmployee: "Добавить персонал",
    edit: "Редактировать",
    pdf: "Скачать PDF",
    prev: "Назад",
    cancel: "Отмена",
    delete: "Удалить",
    choose: "Выбирать",
    choosen: "Выбрано",
    noChoosen: "Не выбран",
    clikForSelect: "Нажмите, чтобы выбрать",
    selectAll: "Выбрать все",
    dutyScheduleTitle: "Примечания",
    dutyScheduleTitle1:
        "1. Данный график дежурств формируется в электронном виде путем случайного выбора, и при формировании графика дежурств отсутствует человеческий фактор.",
    dutyScheduleTitle2:
        "2. Дознаватель, следователь, прокурор или суд вправе привлечь одного из дежурных адвокатов, установленных законом.",
    dutyScheduleTitle3:
        "3. Уважаемые граждане, на основании настоящего графика дежурств адвоката может привлечь только дознаватель, следователь, прокурор или суд. Просим вас не беспокоить их, обратившись напрямую к дежурному адвокату.",
    selectContragent: "Выбор подрядчика",
    address: {
        physical: "Физическое местонахождение",
        location: "Геолокация",
        legal: "Юридический адрес",
        phone: "Рабочий телефон",
        mobilePhone: "Личный телефон",
        email: "Адрес электронной почты",
        physicalRegionId: "Адрес местонахождения",
        physicalAddress: "Адрес",
        legalRegionId: "Юридический адрес",
        legalAddress: "Адрес",
        place: "Улица и дом",
        live: "Адрес проживания",
        fax: "Номер факса (если есть)",
    },
    confirmCreateContragent: "Вы хотите создать эту правозащитную структуру?",
    confirmUpdateCurator:
        "Вы хотите сменить руководителя этой адвокатской структуры?",
    print: "Печать",
    // form fields
    title: "Cерии",
    sedoTab: "Органы прокуратуры",
    depType: "Тип организации",
    regionType: "Территориальный порядок",
    orderNum: "Номер ордеры",
    showMap: "Показать на карте",
    prefix: "Префикс",
    innLawyer: "Идентификационный номер (ИНН)",
    innContragent: "Индивидуальный идентификационный номер (ИНН)",
    dirinn: "ИНН руководителя",
    dirfirstName: "Имя руководителя",
    dirlastName: "фамилия руководителя",
    dirparentName: "отчество руководителя",
    pochtaIndex: "Почтовый индекс",
    passport: "Паспорт",
    passportNumber: "Номер паспорта",
    passportGivenDate: "Дата выдачи паспорта",
    passportGivenWho: "Адрес выдан паспорт",
    passportEndDate: "Срок действия паспорта",
    birthday: "Дата рождения",
    birthyear: "Год рождения",
    onlyYear: "Только год",
    year: "Год",
    deadyear: "Год смерти",
    mobilePhone: "Персональный номер",
    birthCountry: "Страна рождения",
    birthRegion: "Регион рождения",
    birthDistrict: "Родной город",
    fullName: "ФИО",
    birthPlace: "Место рождения",
    passportDetails: "Паспортные данные",
    additional: "Дополнительная информация",
    yearFormat: "гггг",
    dateFormat: "дд-мм-гггг",
    dateFormat2: "гггг-мм-дд",
    photo: "Фото",
    photoType: "Типы изображений",
    beginWorkDate: "Дата выдачи удостоверения адвоката",
    endWorkDatel: "Срок действия лицензии адвоката",
    endWorkDate: "Дата увольнения",
    givenDate: "Дата выдачи",
    endYear: "Год выпуска",
    comment: "Комментарий",
    whereWork: "Место работы",
    givenYear: "Год выпуска",
    dead: "Мертвый",
    noInformation: "Нет информации",
    reason: "Причина",
    workType: "Где, кем работает",
    whoGiven: "данный кем",
    militaryRankNumber: "Номер воинского звания",
    yearOfMilitaryService: "Год службы в прошлом году",
    division: "Раздел",
    groups: "Группа",
    class: "Класс",
    subClass: "Малый класс",
    ifut: "IFUT",
    okonx: "XXTUT",
    pnfl: "ПНФЛ №",
    pnflOrStir: "ПНФЛ / ИНН",
    shortName: "Краткое имя (если есть)",
    byPassportNumber: "Серийный номер паспорта",
    directorPassportSerial: "Серия паспорта руководителя",
    directorPassportNumber: "Номер паспорта руководителя",
    organizedDate: "Дата создания",
    orderFamilyType: "Порядковый номер",
    certificates: "Сертификаты",
    certificateNumber: "Номер удостоверения юриста",
    certificateGivenDate: "Дата выдачи удостоверения адвоката",
    qr_code: "QR код",
    check: "Проверять",
    director: "Директор",
    contragentExistError: "Эта адвокатская структура уже существует",
    contragentExistErrorS: "Данная адвокатская структура не зарегистрирована",
    checkLawyerFromBase: "Проверьте структуру адвокатуры из системы",
    saveContragent: "Добавление адвокатской структуры в систему",
    saveLawyer: "Войти к юристу",
    setCurator: "Директор",
    chooseLicenseFile: "Введение лицензии адвоката",
    addFile: "Добавить файл",
    fileError: "Введите файл",
    journal: "Журнал",
    noteNumber: "Регистрационный номер",
    courseName: "Название курса",
    trainCertificate: "Квалификационный сертификат",
    licenses: "Лицензии",
    licenseTitle: `O'zbekiston respublikasi`,
    licenseTitle1: `adliya vazirligi`,
    "Advokat qo'shish": "Добавить юриста",
    "Birlashma qo'shish": "Добавить слияние",
    "Advokat birlashmasi tahrirlash": "Ассоциация юристов Править",
    legislation: "Законодательство",
    news: "Новости",
    videos: "Видео",
    tutorials: "Гиды",
    lawyerOf: "Advokati",
    License: "Litsenziya",
    speciality: "Mutahasisligi",
    licenseGivenWho: "Лицензированная организация",
    contragentInfo: "Выбранная структура защиты интересов",
    allLawyers: "Всего адвокатов",
    lawyers: "Адвокаты",
    allMaleLawyers: "Количество мужчин",
    allFemaleLawyers: "Количество женщин",
    addPassport: "Загрузить паспорт",
    getPassport: "Посмотреть паспорт",
    getPassportError: "Паспорт не входит",
    lawyerStatus: "Статус юристов",
    minjustorgans: "Территориальные органы юстиции",
    editLicenseStatus: "Изменить статус адвоката",
    ACTIVE: "Лицензия активна",
    INACTIVE: "Лицензия приостановлена",
    TemporarilyInActive: "Статус лицензии временно приостановлен",
    PermanentlyInActive: "Статус лицензии полностью приостановлен",
    null: "Not Found",
    archiveList: "Все документы, связанные с лицензией",
    allList: "История всех лицензий",
    permissions: {
        userManagement: "Управление пользователями",
        permissions: "Разрешения",
        roles: "Роли",
    },
    // end actions
    // page titles
    // References
    references: "Справка",
    //
    academicDegree: "Ученая степень",
    academicDegreeAdd: "Добавить ученую степень",
    academicDegreeUpdate: "Изменить ученую степень",
    //
    academicTitle: "Ученое звание",
    academicTitleAdd: "Добавить ученое звание",
    academicTitleUpdate: "Изменить ученое звание",
    //
    award: "Награда",
    awardAdd: "Добавить награду",
    awardUpdate: "Изменить награду",
    //
    color: "Цвет",
    colorAdd: "Добавить цвет",
    colorUpdate: "Rangni tahrirlash",
    //
    CandidateDoc: "Документ заявителя",
    CandidateDocAdd: "Добавить документ заявителя",
    CandidateDocUpdate: "Изменить документ заявителя",
    //
    educationalInstitution: "Образовательное учреждение",
    educationalInstitutionAdd: "Добавить учебное заведение",
    educationalInstitutionUpdate: "Редактирование учебного заведения",
    //
    education: "Квалификационный уровень",
    educationAdd: "Добавить уровень навыка",
    educationUpdate: "Редактирование уровня квалификации",
    //
    educationType: "Тип учебного заведения",
    educationTypeAdd: "Добавить тип учебного заведения",
    educationTypeUpdate: "Изменить тип учебного заведения",
    //
    familyMember: "Семейное членство",
    familyMemberAdd: "Добавить членов семьи",
    familyMemberUpdate: "Изменить членов семьи",
    //
    gender: "Пол",
    genderAdd: "Добавить пол",
    genderUpdate: "Изменить пол",
    //
    language: "Язык",
    languageAdd: "Добавить язык",
    languageUpdate: "Редактирование языка",
    //
    languageStatus: "Языковой статус",
    languageStatusAdd: "Добавить языковой статус",
    languageStatusUpdate: "Изменить языковой статус",
    //
    militaryRank: "Воинское звание",
    militaryRankAdd: "Добавить воинское звание",
    militaryRankUpdate: "Изменить воинское звание",
    //
    partyMembership: "Членство в партии",
    partyMembershipAdd: "Добавить членство в партии",
    partyMembershipUpdate: "Изменить членство в партии",
    //
    passportSerial: "Паспорт серии",
    passportSerialAdd: "Добавить серию паспорта",
    passportSerialUpdate: "Изменить серию паспорта",
    //
    rectal: "Военная комиссия",
    rectalAdd: "Добавить военную комиссию",
    rectalUpdate: "Редактирование Военной комиссии",
    //
    specDiploma: "Специальность",
    specDiplomaAdd: "Добавить специализацию",
    specDiplomaUpdate: "Изменить специализацию",
    //
    typePosition: "Категория должности",
    typePositionAdd: "Добавить категорию должности",
    typePositionUpdate: "Изменить категорию должности",
    //
    region: "Область",
    departments: "Структуры защиты",
    soato: "SOATO",
    pc: "PC",
    SP_ID: "SP",
    //
    department: "Организация",
    departmentAdd: "Добавить организацию",
    departmentUpdate: "Изменить организацию",
    //
    departmentType: "Тип организации",
    departmentTypeAdd: "Добавить тип организации",
    departmentTypeUpdate: "Изменить тип организации",
    //
    departmentLocationType: "Тип расположения организации",
    departmentLocationTypeAdd: "Добавить тип местоположения организации",
    departmentLocationTypeUpdate: "Изменить тип местоположения организации",
    //
    workMode: "Тип работы",
    workModeAdd: "Добавить тип работы",
    workModeUpdate: "Изменить тип задания",
    yuridik: "Юридический",
    //
    employee: "Пользователь",
    employeeAdd: "Добавить пользователь",
    employeeUpdate: "Изменить пользователя",
    //
    contragent: "Адвокатские ассоциации",
    contragentAdd: "Добавить ассоциации адвокатов",
    contragentUpdate: "Редактирование адвокатских ассоциаций",
    //
    maritalStatus: "Семейное положение",
    maritalStatusAdd: "Добавить семейное положение",
    maritalStatusUpdate: "Изменить семейное положение",
    //
    passportType: "Тип паспорта",
    passportTypeAdd: "Добавить тип паспорта",
    passportTypeUpdate: "Изменить тип паспорта",
    //
    licenseType: "Серия лицензий",
    licenseTypeAdd: "Добавить серию лицензий",
    licenseTypeUpdate: "Обновление серии лицензий",
    //
    licenseSerial: "Серия лицензий",
    licenseSerialAdd: "Добавить серию лицензий",
    licenseSerialUpdate: "Изменить серию лицензий",
    //
    licenseNumber: "Номер лицензии",
    licenseGivenDate: "Дата выдачи лицензии",
    licenseEndDate: "Срок действия лицензии",
    licenseSerialAndNumber: "Серия и номер лицензии",
    //
    ownershipType: "Тип собственности",
    ownershipTypeAdd: "Добавить тип недвижимости",
    ownershipTypeUpdate: "Изменить тип недвижимости",
    //
    lawyerAdd: "Добавить юристов",
    lawyerUpdate: "Редактирование юристов",
    //
    typesOfEconomicActivity: "Виды экономической деятельности",
    typesOfEconomicActivityAdd: "Добавить вид экономической деятельности",
    typesOfEconomicActivityUpdate: "Изменить вид экономической деятельности",
    //
    lawOffices: "Тип адвокатской структуры",
    lawOfficesAdd: "Добавить тип структуры защиты интересов",
    lawOfficesUpDate: "Изменить тип структуры защиты интересов",
    //
    lawyerProfile: "Профили юристов",
    //
    nationality: "Национальность",
    nationalityAdd: "Добавить нацию",
    nationalityUpdate: "Редактировать нацию",
    //
    licenseStatus: "Легальное положение",
    licenseStatusAdd: "Добавить юридический статус",
    licenseStatusUpdate: "Изменить статус адвоката",

    //profile/////\\\\\    PROFILE      \\\\\\\\/////////
    /////\\\\\\                 \\\\\\\////////

    lawyerOne: "Адвокат",
    profileInfo: "Информация профиля",
    profileInfoUpdate: "Изменить информацию профиля",
    activity: "Рабочая деятельность",
    profileAwards: "Государственные награды",
    deputy: "Заместитель",
    lawyerLicence: "Лицензия адвоката",
    //
    contragentOne: "Коллегия адвокатов",
    users: "Пользователи",
    control: "Управление",
    roles: "Роли",
    role: "Роли",
    viewdate: "просмотреть информацию",
    confirmation: "Подтверждение",
    viewRoles: "Посмотреть роли",
    depDetails: "Организационная информация",
    depName: "Название организации",
    depNotSelected: "Организация не выбрана",
    map: "Место нахождения",
    reference: "Справка",
    other: "Другой",
    selectOne: "Выберите один",
    selectOneOrMore: "Выберите один или несколько",
    enterToRemove: "Нажмите, чтобы закрыть",
    enterToSelect: "Нажмите, чтобы выбрать",
    cv: "Объектив",
    findInfo: "Принесите данные",
    chooseImage: "Выберите изображение",
    deleteImage: "Удалить изображение",
    statusSituation: "Статус",
    // Login info
    loginUp: "Министерство юстиции Республики Узбекистан",
    loginDown: "Автоматизированная система подбора юристов",
    Username: "Имя пользователя",
    userNameRequired: "Требуется имя пользователя",
    passwordRequired: "Требуется пароль",
    signIn: "Войти",
    signOut: "Выход",
    userProfile: "Профиль пользователя",
    dashboardHeader: "Статистика межрегиональных юридических лиц",
    dashboardHeader1: "Статистика по юридическим лицам",
    allCount: "Общее число",
    lawyerF: "Фирма адвокатов",
    lawyerB: "Бюро адвокатов",
    lawyerH: "Совет адвокатов",
    totalInfo: "Вся информация",
    Qolganlari: "Другие",

    /* addition */

    curentStateApplication: "Текущий статус вашей заявки",
    archiveApplication: "Архив  заявки",
    sendedDate: "Дата отправлено",
    send: "Отправлять",
    passportOrOneIdData:
        "Копия паспорта гражданина Республики Узбекистан или сведения о удостоверении личности",
    educationData:
        "Копия диплома о высшем образовании или свидетельства о удостоверении (записи об эквивалентности) документа о высшем образовании, полученном в иностранном образовательном учреждении уполномоченным органом",
    workBookData:
        "Заверенная копия трудовой книжки или выписка из системы «Электронная трудовая книжка», подтверждающая стаж работы по юридической специальности",
    internshipData:
        "Описание, выданное юридической фирмой, в которой заявитель проходил стажировку",
    $3x4Data:
        "2 цветные фотографии заявителя размером 3 х 4 см (сделанные в течение последних 6 месяцев)",
    abilityDealingData: "Сведения о дееспособности заявителя",
    isConvictedData: "Информация о том, был ли заявитель осужден.",

    //REGIONS
    Uzbekistan: "Республика Узбекистан",
    Surxandaryo: "Сурхандарьинская область",
    Toshkentshahar: "город Ташкент",
    Jizzah: "Джизакская область",
    Qashqadaryo: "Кашкадарьинская область",
    Sirdaryo: "Сырдарьинская область",
    Toshkentviloyati: "Ташкентская область",
    Namangan: "Наманганская область",
    Fargona: "Ферганская область",
    QoraqalpogistonRespublikasi: "Республика Каракалпакстан",
    Navoi: "Навоийская область",
    Buxoro: "Бухарская область",
    Xorazm: "Хорезмская область",
    Samarqand: "Самаркандская область",
    Andijon: "Андижанская область",
    archive: "Aрхив",
    totalContragent: "Общее количество адвокатских структур",
    cancelStaff: "Увольнение",
    /* home banner */
    homeBannerText: "Наш сайт предлагает вам",
    serviceLawyer: "Информационная система юридической помощи",
    register: "РЕГИСТРАЦИЯ",
    sign_in: "ВОЙТИ",

    /* addition */
    aboutUs: "О нас",
    recentNews: "Последние новости",
    moreNews: "Больше новостей",
    readMore: "Читать далее",
    moreLawyers: "Больше юристов",
    detailed: "Подробный",
    categories: "Категории",
    statistics: "Cтатистика",
    branches: "Филиалы",
    branchesLists: "Все подразделения",
    usefulLinks: "Полезные ссылки",
    presidentSite: "Официальный сайт Президента Республики Узбекистан",
    uniqueCountryService: "Единственный интерактивный портал госуслуг",
    nationalTasks: "Национальные цели и задачи в области устойчивого развития",
    lexUz: "Национальная база данных законодательства Республики Узбекистан",
    adliya: "Официальный сайт Министерства юстиции Республики Узбекистан",
    yurxizmat:"Система правового самообслуживания “Legal Tech”",
    oliyMajlis: "Законодательная палата Олий Мажлиса Республики Узбекистан",
    openDataPortal: "Открытый информационный портал Республики Узбекистан",
    sendDoc:
        "Если вы претендуете на адвоката, вы можете отправить документы через эту форму",
    telegramBotClick: `По вопросам технических проблем на сайте e-advokat обращайтесь через этого бота`,
    /* footer */

    adres: "Республика Узбекистан 100047, г.Ташкент, улица Саилгох, 5",
    aboutMinistry: "О министерстве",
    ourCareer: "Наша деятельность",
    openJustice: "Открытое правосудие",
    vacancy: "Ваканции",
    serviceInformation: "Информационная служба",
    e_library: "Электронная библиотека",
    interactive: "Интерактивные услуги",
    contact: "Коммуникация",
    corruption: "Коррупция",
    genderEquality: "Гендерное равенство",
    appeal: "Oбращаться",
    bankName: "Название банка",
    citizensInTheSocialSecurityRegister:
        "Получение юридической помощи за счет государства",
    citizensInTheSocialSecurityRegisterCreate:
        "Подача заявления на получение юридической помощи за счет государства",
    applications: "Запросы",
    passportSeriesAndNumber: "Серия и номер паспорта",
    forPersonsWithDisabilities: "Для людей с ограниченными возможностями",
    pinfl: "ПИНФЛ",
    caseNumber: "Номер дела",
    eSign: "ЭЦП",
    subDepartment: "Подорганизация",
    instance: "Пример",
    met: "Я встретил",
    correspondence: "Переписки",
    note: "Примечание !!!",
    applicationsCreateInfo:
        'Если вы не зарегистрированы в информационной системе "Единый реестр социальной защиты\'" как малообеспеченное лицо, ваше заявление на получение юридической помощи за счет государства будет автоматически отклонено.',
    courtName: "Название суда",
    theTimeWhenTheCourtIsAdjourned: "Время перерыва в суде",
    internalNumberOfTheWorkDocument: "Внутренний номер рабочего документа",
    written: "Написано",
    noMoreThan2500Words: "Не более 2500 слов!",
    meetingDate: "Uchrashuv kunini tanlang",
    meetingTime: "Uchrashuv taqtini tanlang",
    birthDate: "Tug'ilgan kun",
    back: "Orqaga",
    description: "Izoh",
    directoryRequestType: "Ariza turi",
    diplom: {
        title: "Информация о дипломе",
    },
    new: "Новый",
    work: {
        title: "Трудовая деятельность",
        start_date: "Иш бошланган санаси",
        end_date: "Иш тугатган санаси",
        company_name: "Ташкилот",
        company_inn: "СТИР",
        position_name: "Лавозим",
        structure_name: "Бўлим",
    },
    conviction: {
        title: "Статус осуждения или отсутствия осуждения",
    },
    expertise: {
        type: "Тип экспертизы",
        organization: "Организация",
        researchType: "Тип исследования",
        comment: "Комментарий",
        paymentDetail:"Детали платежа",
        paymentStatus:"Статус платежа",
        actionType:"Тип действия",
        response:"Ответ",
        paymentAt:"Оплата в",
        conclusionNumber:"Номер заключения",
        deadline:"Крайний срок",
        docType:"Тип",
        status: {
            DIRECTED: "Направленный",
            PAUSED:"Приостановлен",
            REJECTED:"Отклоненный",
            TEMPORARY:"Временная",
            NEW:"Новая",
            UNDER_CONSIDERATION:"На рассмотрении",
            ON_APPROVAL_FOR_DIRECTOR:"На рассмотрении",
            ON_APPROVAL_FOR_DEPUTY_DIRECTOR:"На рассмотрении",
            PENDING:"В распределение",
            IN_PRODUCTION:"На производстве",
            ON_AGREEMENT:"На согласовании директора",
            SENT:"На согласовании",
            COMPLETED:"Завершен",
            FOR_REJECTION:"Для отказа",
            NOT_APPLICABLE:"Не применимо"
        }
    }
};
